<script setup lang="ts">
import Footer from '@/components/layouts/Footer.vue';
import Header from '@/components/layouts/Header.vue';

const route = useRoute()

</script>
<template>
    <v-app>
        <Header />
        <slot />
        <Footer />
    </v-app>
</template>
<style scoped lang="scss">

</style>
